
html {
	font-size: 1.1rem;
}
.content {
	padding-top: 20px;
	padding-bottom: 30px;
}
.is-empty .form-control {
	border-color: #5aaaff;
}

.dioelogo[data-v-2a80fb00] {
	max-width: 600px;
}

.weitereangaben[data-v-2a78a883] {
	position: relative;
	margin-top: 30px;
	margin-bottom: 30px;
	padding-top: 15px;
	padding-bottom: 1px;
}
.weitereangaben[data-v-2a78a883]:before, .weitereangaben[data-v-2a78a883]:after {
	content: "";
	position: absolute;
	background: #d4edda;
	left: -15px;
	top: 0px;
	bottom: 0px;
	width: 3px;
}
.weitereangaben[data-v-2a78a883]:after {
	left: inherit;
	right: -15px;
}
.loading[data-v-2a78a883] {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding-top: 40vh;
	text-align: center;
	color: #fff;
	background: #000;
	background: rgba(0,0,0,0.5);
	font-size: 60px;
}

.radio-spacer[data-v-4404c8de] {
	background: #000;
	height: 2px;
	margin-top: 10px;
	margin-right: 10px;
	margin-left: 10px;
}
.radio[data-v-4404c8de] {
	width: 20px;
	height: 20px;
	margin-top: 1px;
	margin-left: 0px;
}
.radio-flex[data-v-4404c8de] {
	min-height: 20px;
}
.disabled[data-v-4404c8de] {
	opacity: 0.5;
}


























































































#svg-layer-land > path[data-v-46a9df7c] {
	fill: #b4b3f4;
	fill-opacity: 1;
	stroke: #38385b;
	stroke-width: 1.84806633;
	stroke-linecap: round;
	stroke-linejoin: miter;
	stroke-miterlimit: 4;
	stroke-dasharray: none;
	stroke-opacity: 1;
}
#svg-layer-marker > g[data-v-46a9df7c] {
	cursor: default;
}

.ort-btn[data-v-3387be83] {
	background: #ddd;
	margin: 5px;
	padding: 0px 5px;
	cursor: pointer;
}
.ort-btn.selected[data-v-3387be83] {
	background: #ccf;
}
.ort-btn[data-v-3387be83]:hover {
	background: #dde;
}
.hidden[data-v-3387be83] {
	visibility: hidden;
}
.loading[data-v-3387be83] {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding-top: 40vh;
	text-align: center;
	color: #fff;
	background: #000;
	background: rgba(0,0,0,0.5);
	font-size: 60px;
}
#svg-layer-land > path[data-v-3387be83] {
	fill: #b4b3f4;
	fill-opacity: 1;
	stroke: #38385b;
	stroke-width: 1.84806633;
	stroke-linecap: round;
	stroke-linejoin: miter;
	stroke-miterlimit: 4;
	stroke-dasharray: none;
	stroke-opacity: 1;
}
#svg-layer-land > path.active[data-v-3387be83] {
	fill: #9998f0;
}
#svg-layer-marker > g[data-v-3387be83] {
	cursor: pointer;
}
#svg-layer-marker > g.selected[data-v-3387be83] {
	fill: #00428a;
}
#svg-layer-marker > g.selected > circle[data-v-3387be83] {
	fill: #007bff;
	stroke: #00428a;
	stroke-width: 3;
}
#svg-layer-marker > g[data-v-3387be83]:hover {
	fill: #00428a;
}































































































































































.loading[data-v-3de6d400] {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding-top: 40vh;
	text-align: center;
	color: #fff;
	background: #000;
	background: rgba(0,0,0,0.5);
	font-size: 60px;
}
rect.chartdr[data-v-3de6d400] {
	fill: #ffc107;
}
rect.chartbl[data-v-3de6d400] {
	fill: #007bff;
}
rect.chartho[data-v-3de6d400] {
	fill: #28a745;
}
rect.chart.active[data-v-3de6d400] {
	stroke-width: 1;
	stroke: #dc3545;
}
.colbox[data-v-3de6d400] {
	display: inline-block;
	width: 26px;
}
.colboxdr[data-v-3de6d400] {
	background: #ffc107;
}
.colboxbl[data-v-3de6d400] {
	background: #007bff;
}
.colboxho[data-v-3de6d400] {
	background: #28a745;
}
